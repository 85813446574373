import {
    BUTTON_ANIMATION_NONE,
    BUTTON_COLOR_NEUTRAL,
    BUTTON_DISTANCE_FLOATING,
    BUTTON_SIZE_MEDIUM,
    BUTTON_TYPE_SHADOW,
    POSITION_BOTTOM_RIGHT,
    TICKET_ICON,
    TriggerStyle,

} from "../../../Contracts/WidgetTypes/Common/TriggerStyle";

const defaultTriggerStyle:TriggerStyle = {
    icon: TICKET_ICON,
    position: POSITION_BOTTOM_RIGHT,
    label: true,
    buttonSize: BUTTON_SIZE_MEDIUM,
    buttonAnimation: BUTTON_ANIMATION_NONE,
    buttonType: BUTTON_TYPE_SHADOW,
    buttonColor: BUTTON_COLOR_NEUTRAL,
    buttonDistance: BUTTON_DISTANCE_FLOATING,
};


export const applyDefaultStyle = (styleConfig: Partial<TriggerStyle>): Required<TriggerStyle> => ({
    ...defaultTriggerStyle,
    ...styleConfig
})

