import {App} from "vue";
import {getLogger} from "../Services/LoggingService";
import {waitToHaveElement} from "./index";
import {legacyParseElementAttributes, parseDataAttributes} from "../Composables/useCustomElementProps";
import {GlobalId} from "../GlobalServices/GlobalIdService";
import {Widget, WidgetInput} from "../Contracts/WidgetTypes";
import {
    FLOATING_WIDGET_TYPE
} from "../Contracts/WidgetTypes/FloatingWidgetType";
import {hydrateDefaults} from "../Composables/useWidgetConfiguration/Mappers";
import {isValidWidgetConfiguration} from "../Composables/useWidgetConfiguration/Validators";
import {BOOK_NOW_WIDGET_TYPE} from "../Contracts/WidgetTypes/BookNowType";
import {bootstrapI18n} from "../Services/TranslationService";
import {UncaughtApplicationError} from "../Errors/UncaughtApplicationError";
import {ExperienceService} from "../Services/BookingkitApi";
import {AB_TESTER_SERVICE, API_V4} from "../Constants/di";
import {IABTesterService} from "../GlobalServices/ABTester";
import makeBookingkitApiService from "./Factories/BookingkitApiFactory";
import SetScopedColours from "./SetScopedColouts";

function deepMerge<T extends Record<string, any>>(target: T, source: T): T {
    if (Array.isArray(target) && Array.isArray(source)) {
        // @ts-ignore
        return [...source, ...target] as T; // Ensure source takes precedence while keeping both arrays
    } else if (typeof target === 'object' && typeof source === 'object') {
        return Object.keys({ ...target, ...source }).reduce((acc, key) => {
            // @ts-ignore
            acc[key] =
                key in target && key in source
                    ? deepMerge(target[key], source[key]) // Recursively merge
                    : source[key] ?? target[key];
            return acc;
        }, {} as T);
    }
    return source; // If not an object/array, source always wins
}

export const boostrapCustomElementApp = async <W extends Widget>(
    type: typeof FLOATING_WIDGET_TYPE | typeof BOOK_NOW_WIDGET_TYPE,
    app: App<any>) => {

    const root = await waitToHaveElement(app);
    const hostElement: HTMLElement = root.host as HTMLElement;
    const configurationFromDataProperties =
        hostElement.dataset?.widgetId
            ? parseDataAttributes(hostElement.dataset)
            : legacyParseElementAttributes(hostElement.attributes);

    if (!configurationFromDataProperties.id) {
        throw new Error('widget must have an id');
    }

    const widgetId = configurationFromDataProperties.id;
    const bookingkitApiService =
        makeBookingkitApiService({
            logger: getLogger(),
            widgetId
        });

    const remoteWidget = await bookingkitApiService.widgetService.getWidget();
    const instanceId = GlobalId.generateWidgetId();
    const hydratedConfiguration: WidgetInput = remoteWidget.config
        ? deepMerge(remoteWidget.config, configurationFromDataProperties)
        : configurationFromDataProperties;

    isValidWidgetConfiguration({
        ...hydratedConfiguration,
        type
    } as WidgetInput);


    const configuration = hydrateDefaults({
        ...hydratedConfiguration,
        type,
        supplierId: remoteWidget.supplierId,
    }, instanceId) as W;

    const i18n = bootstrapI18n({
        locale: configuration.locale,
        customMessages: configuration.localisation,
    });
    app.use(i18n);
    app.config.errorHandler = (err) => {
        getLogger().error(UncaughtApplicationError.fromUnknown(err));
    }
    app.provide<ExperienceService>(API_V4,
        bookingkitApiService.experienceService
    );

    const abTesterService:IABTesterService = window.bookingkitServices.abTester;
    app.provide(AB_TESTER_SERVICE, abTesterService);
    SetScopedColours({
        root,
        configuration
    });


    return {
        configuration,
        bookingkitApiService,
    }
}