import {WidgetResponse} from "@bookingkit-private/api-v4/models";
import {Widget} from "../types";
import WidgetServiceError from "../Errors/WidgetServiceError";
import {FloatingWidgetConfigurationInput} from "../../../Contracts/WidgetTypes/FloatingWidgetType";

export const fromAPIWidget = (widgetResponse: WidgetResponse):Widget => {
    const {vendor_id, widget_id, config} = widgetResponse;
    if (vendor_id === undefined) {
        throw WidgetServiceError.fromMissingProperty('vendor_id', 'string', vendor_id);
    }

    return {
        supplierId: vendor_id as string,
        widgetId: widget_id as string,
        config: config as unknown as FloatingWidgetConfigurationInput| undefined,
    }
};
