import {
    BaseWidgetConfiguration,
    BaseWidgetConfigurationInput,
    THEME_AUTO,
    THEME_DARK,
    THEME_LIGHT
} from "../../../Contracts/WidgetTypes/BaseWidget";
const MapThemeMode = (mode?: string): BaseWidgetConfiguration['themeConfiguration']['mode'] => {
    if(mode && mode === THEME_DARK || mode === THEME_LIGHT) {
        return mode;
    }
    const isDarkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
    return isDarkMode ? THEME_DARK :THEME_LIGHT;
}


const defaultWidgetThemeConfiguration:BaseWidgetConfiguration['themeConfiguration'] =
    {
        mode: MapThemeMode(THEME_AUTO),
        light: {
            colours: {
                colorPrimary1: "216, 100%, 94%",
                colorPrimary2: "216, 100%, 73%",
                colorPrimary3: "216, 100%, 46%",
                colorPrimary4: "216, 100%, 35%",
                colorPrimary5: "216, 100%, 23%",
                colourNeutral1: "0, 0%, 100%",
                colourNeutral2: "0, 0%, 95.4%",
                colourNeutral3: "0, 0%, 87%",
                colourNeutral4: "0, 0%, 77%",
                colourNeutral5: "0, 0%, 69%",
                colourNeutral6: "0, 0%, 60%",
                colourNeutral7: "0, 0%, 46%",
                colourNeutral8: "0, 0%, 29%",
                colourNeutral9: "0, 0%, 20%",
                colourNeutral10: "0, 0%, 0%",
                textOnPrimary: "0, 0%, 100%"
            }
        },
        dark: {
            colours: {
                colorPrimary1: "216, 100%, 46%",
                colorPrimary2: "209, 100%, 50%",
                colorPrimary3: "205, 100%, 65%",
                colorPrimary4: "207, 100%, 76%",
                colorPrimary5: "207, 100%, 94%",
                colourNeutral1: "0, 0%, 7%",
                colourNeutral2: "0, 0%, 20%",
                colourNeutral3: "0, 0%, 29%",
                colourNeutral4: "0, 0%, 46%",
                colourNeutral5: "0, 0%, 60%",
                colourNeutral6: "0, 0%, 69%",
                colourNeutral7: "0, 0%, 77%",
                colourNeutral8: "0, 0%, 87%",
                colourNeutral9: "0, 0%, 95.4%",
                colourNeutral10: "0, 0%, 100%",
                textOnPrimary: "0, 0%, 0%"
            }
        }
    };

const fillColourPalette = (
    defaultColours: BaseWidgetConfiguration['themeConfiguration']['light']['colours'],
    colours?: Partial<BaseWidgetConfiguration['themeConfiguration']['light']['colours']>
): BaseWidgetConfiguration['themeConfiguration']['light']['colours'] => {
    if(!colours){
        return defaultColours;
    }
    return {...defaultColours, ...colours}
}
export const fromInputWidgetThemeToWidgetTheme = (
    theme: BaseWidgetConfigurationInput['themeConfiguration']
): BaseWidgetConfiguration['themeConfiguration'] => {
    if(!theme) {
        return defaultWidgetThemeConfiguration;
    }
    return {
        mode: MapThemeMode(theme.mode),
        light: {
            colours: fillColourPalette(defaultWidgetThemeConfiguration.light.colours, theme.light?.colours, )
        },
        dark: {
            colours: fillColourPalette(defaultWidgetThemeConfiguration.dark.colours, theme.dark?.colours)
        }
    }

}