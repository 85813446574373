import {LocalStorageService} from "./LocalStorageService";

export class ExpiringLocalStorageService extends LocalStorageService {
    constructor(private readonly validityPeriod: number) {
        super();
    }

    set(key: string, value: any): void {
        super.set(key, {value, setAt: Date.now()});
    }

    get(key: string): any {
        const item = super.get(key);
        if (!item) {
            return null;
        }
        if (item.setAt < Date.now() - this.validityPeriod * 1000) {
            super.delete(key);
            return null;
        }
        return item.value;
    }
}
