import {Widget} from "../Contracts/WidgetTypes";

export default function SetScopedColours<W extends Widget>({root, configuration}: {root: ShadowRoot, configuration: W}) {
    const isDarkMode= configuration.themeConfiguration.mode === 'dark';
    const configColours = isDarkMode ? configuration.themeConfiguration.dark.colours : configuration.themeConfiguration.light.colours;
    if(isDarkMode){
        root.host.classList.add('dark');
    }
    if(configColours?.colorPrimary1){
        (root.host as HTMLElement).style.setProperty('--color-primary-1-hsl', configColours?.colorPrimary1);
        (root.host as HTMLElement).style.setProperty('--color-primary-1', 'hsl(var(--color-primary-1-hsl))');
    }
    if(configColours?.colorPrimary2){
        (root.host as HTMLElement).style.setProperty('--color-primary-2-hsl', configColours?.colorPrimary2);
        (root.host as HTMLElement).style.setProperty('--color-primary-2', 'hsl(var(--color-primary-2-hsl))');
    }
    if(configColours?.colorPrimary3){
        (root.host as HTMLElement).style.setProperty('--color-primary-3-hsl', configColours?.colorPrimary3);
        (root.host as HTMLElement).style.setProperty('--color-primary-3', 'hsl(var(--color-primary-3-hsl))');
    }
    if(configColours?.colorPrimary4){
        (root.host as HTMLElement).style.setProperty('--color-primary-4-hsl', configColours?.colorPrimary4);
        (root.host as HTMLElement).style.setProperty('--color-primary-4', 'hsl(var(--color-primary-4-hsl))');
    }
    if(configColours?.colorPrimary5){
        (root.host as HTMLElement).style.setProperty('--color-primary-5-hsl', configColours?.colorPrimary5);
        (root.host as HTMLElement).style.setProperty('--color-primary-5', 'hsl(var(--color-primary-5-hsl))');
    }
    if(configColours?.textOnPrimary) {
        (root.host as HTMLElement).style.setProperty('--fw-text-on-primary-hsl', configColours?.textOnPrimary);
        (root.host as HTMLElement).style.setProperty('--fw-text-on-primary', 'hsl(var(--fw-text-on-primary-hsl))');

    }
    if(configColours?.colourNeutral1) {
        (root.host as HTMLElement).style.setProperty('--color-neutral-1-hsl', configColours?.colourNeutral1);
        (root.host as HTMLElement).style.setProperty('--color-neutral-1', 'hsl(var(--color-neutral-1-hsl))');
    }
    // DO the same for all the other neutral colours up to ten
    if(configColours?.colourNeutral2) {
        (root.host as HTMLElement).style.setProperty('--color-neutral-2-hsl', configColours?.colourNeutral2);
        (root.host as HTMLElement).style.setProperty('--color-neutral-2', 'hsl(var(--color-neutral-2-hsl))');
    }
    if(configColours?.colourNeutral3) {
        (root.host as HTMLElement).style.setProperty('--color-neutral-3-hsl', configColours?.colourNeutral3);
        (root.host as HTMLElement).style.setProperty('--color-neutral-3', 'hsl(var(--color-neutral-3-hsl))');
    }
    if(configColours?.colourNeutral4) {
        (root.host as HTMLElement).style.setProperty('--color-neutral-4-hsl', configColours?.colourNeutral4);
        (root.host as HTMLElement).style.setProperty('--color-neutral-4', 'hsl(var(--color-neutral-4-hsl))');
    }
    if(configColours?.colourNeutral5) {
        (root.host as HTMLElement).style.setProperty('--color-neutral-5-hsl', configColours?.colourNeutral5);
        (root.host as HTMLElement).style.setProperty('--color-neutral-5', 'hsl(var(--color-neutral-5-hsl))');
        (root.host as HTMLElement).style.setProperty('--color-neutral-5-transparency-20', 'hsla(var(--color-neutral-5-hsl), 0.2)');

    }
    if(configColours?.colourNeutral6) {
        (root.host as HTMLElement).style.setProperty('--color-neutral-6-hsl', configColours?.colourNeutral6);
        (root.host as HTMLElement).style.setProperty('--color-neutral-6', 'hsl(var(--color-neutral-6-hsl))');
    }
    if(configColours?.colourNeutral7) {
        (root.host as HTMLElement).style.setProperty('--color-neutral-7-hsl', configColours?.colourNeutral7);
        (root.host as HTMLElement).style.setProperty('--color-neutral-7', 'hsl(var(--color-neutral-7-hsl))');
    }
    if(configColours?.colourNeutral8) {
        (root.host as HTMLElement).style.setProperty('--color-neutral-8-hsl', configColours?.colourNeutral8);
        (root.host as HTMLElement).style.setProperty('--color-neutral-8', 'hsl(var(--color-neutral-8-hsl))');
    }
    if(configColours?.colourNeutral9) {
        (root.host as HTMLElement).style.setProperty('--color-neutral-9-hsl', configColours?.colourNeutral9);
        (root.host as HTMLElement).style.setProperty('--color-neutral-9', 'hsl(var(--color-neutral-9-hsl))');
    }
    if(configColours?.colourNeutral10) {
        (root.host as HTMLElement).style.setProperty('--color-neutral-10-hsl', configColours?.colourNeutral10);
        (root.host as HTMLElement).style.setProperty('--color-neutral-10', 'hsl(var(--color-neutral-10-hsl))');
        (root.host as HTMLElement).style.setProperty('--color-neutral-10-transparency-50', 'hsla(var(--color-neutral-10-hsl), 0.5)');
    }
}