export type CalendarDayOption = {
    optionId: string;
    availableSlots: number;
}
export class ExperienceCalendarDay {
    date: Date;
    options: CalendarDayOption[];
    constructor(
        date: Date,
        options: CalendarDayOption[]
    ) {
        this.date = date;
        this.options = options;
    }
    get totalAvailableSlots(): number {
        return this.options.reduce((acc, option) => acc + option.availableSlots, 0);
    }
    get totalOptions(): number {
        return this.options.length;
    }
    get hasAvailableSlots(): boolean {
        return this.bookableOptions.length > 0;
    }
    get bookableOptions(): CalendarDayOption[] {
        return this.options.filter(option => option.availableSlots > 0);
    }
}