export class ClientNetworkError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ClientNetworkError);
        }

        this.name = 'ClientNetworkError';
    }

}

export default { ClientNetworkError };
