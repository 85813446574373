import {IClientStorageService} from "../../Contracts/GloablServices/IClientStorageService";


export class LocalStorageService implements IClientStorageService {
    set(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    get(key: string): any {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    }

    delete(key: string): void {
        localStorage.removeItem(key);
    }
}
