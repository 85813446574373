import { AxiosError, AxiosResponse } from 'axios';
import {CanceledRequestError} from "./Errors/CanceledRequestError";
import {InvalidRequestError} from "./Errors/InvalidRequestError";
import {ClientNetworkError} from "./Errors/ClientNetworkError";

export const onFullFilled = (response: AxiosResponse) => response;

const getError = (error?: any): Error => {
    // This means that the request never reached the server,
    // It is usually the case when the request is blocked by the ad-blockers or
    // the client being offline
    if(error.code === 'ERR_NETWORK')
    {
        return new ClientNetworkError(error.message);
    }

    if (error?.message === 'canceled') {
        return CanceledRequestError.fromCanceledRequest();
    }

    if((error as AxiosError)?.response?.status?.toString().startsWith('4') === true) {
        return InvalidRequestError.fromFourHundrishCode((error as AxiosError)?.response?.status as number, error?.message);
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return error;
}

export const makeOnReject = (logger: ILogger) => (error?: any): Promise<any> => {
    const errorInstance = getError(error);
    if(!(errorInstance instanceof CanceledRequestError) && !(errorInstance instanceof ClientNetworkError)) {
        logger.error(errorInstance);
    }
    return Promise.reject(errorInstance);
};
