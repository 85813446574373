import {GlobalEventBus} from "../GlobalEventBus";
import {WIDGET_STATE_EVENTS} from "../../Events/Widget/State";
import ROUTE_NAMES from "../../Widgets/ModalWidget/Routes/constants";
import {DateTimeSelectionProps} from "../../Components/DateAndTimeSelection/DateAndTimeSelectionProps";
import {WidgetLocationRaw} from "../../Composables/WidgetNavigation/contracts";
import {Widget} from "../../Contracts/WidgetTypes";
import {CHECKOUT_EVENTS} from "../../Events/Checkout/events";


declare global {
    interface Window {
        dataLayer?: (AcceptedEvents)[];
        gtag?: (event: string, eventName: string, args: any) => void;
    }
}

export class GtmAnalytics {
    private eventBus: typeof GlobalEventBus
    public constructor({eventBus}: { eventBus: typeof GlobalEventBus}) {
        this.init();
        this.eventBus = eventBus;
        this.listen();
    }

    init() {
        window.dataLayer = window?.dataLayer || [];
    }

    listen() {
        this.eventBus.listen(WIDGET_STATE_EVENTS.WIDGET_NAVIGATION_CHANGED, this.listenToNavigationChanged);
        this.eventBus.listen(WIDGET_STATE_EVENTS.WIDGET_ELEMENT_CLICK, this.listenToElementCLick);
        this.eventBus.listen(CHECKOUT_EVENTS.MESSAGE_FROM_CHECKOUT, this.listenToCheckoutMessage);
    }

    private readonly listenToCheckoutMessage = (messageData: any) => {
        this.addToDataLayer(messageData);
    }

    private addToDataLayer = (event: AcceptedEvents) => {
        if(window.gtag){
            window.gtag?.('event',event.event, event.properties ? {...event.properties, debug_mode: false,} : {...event});
        } else {
            window.dataLayer?.push(event);
        }

    }

    private listenToElementCLick = (payload: {
        element: string,
        elementType: ClickEventElement,
        widget: Widget
    }) => {
            this.addToDataLayer({
                event: 'click',
                properties:
                    {
                        element: payload.element,
                        element_type: payload.elementType,
                    }
            });


    };
    private listenToNavigationChanged = (data:  WidgetLocationRaw & {widget: Widget }) => {

        switch (data.name) {
            case ROUTE_NAMES.BUTTON_VIEW:
                this.addToDataLayer({
                    event: 'element_view',
                    properties:
                        {
                            element: 'floating_button.button',
                        }
                });
                break;
            case ROUTE_NAMES.EXPERIENCE_LIST:
                this.addToDataLayer({
                    event: 'page_view',
                    properties:
                        {
                            page_title: 'floating_widget.list_widget',
                        }
                });
                break;
            case ROUTE_NAMES.DATE_TIME_SELECTION:
                this.addToDataLayer({
                    event: 'page_view',
                    properties:
                        {
                            page_title: 'floating_widget.experience_details',
                        }
                });
                this.addToDataLayer({
                    event: 'view_item',
                    properties:
                        {
                            items: [
                                {
                                    item_id: (data.props as DateTimeSelectionProps).experience.id,
                                    item_name: (data.props as DateTimeSelectionProps).experience.title,
                                    location_id: (data.props as DateTimeSelectionProps).experience.experienceLocation.placeId,
                                    item_category: 'ticket',
                                }
                            ]
                        }
                });
                break;
            case ROUTE_NAMES.CHECKOUT:
                this.addToDataLayer({
                    event: 'page_view',
                    properties:
                        {
                            page_title: 'floating_widget.checkout',
                        }
                });
                break;
            default:
                // do nothing
        }
    }
}