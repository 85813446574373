export const AFFILIATE_LINK_EVENTS = {
    AFFILIATE_LINK_DETECTED: 'affiliate-link-detected',
} as const;


export type AffiliateLinkEventsConstructor = {
    [AFFILIATE_LINK_EVENTS.AFFILIATE_LINK_DETECTED]: {affiliateLink: string},
}

export type AffiliateLinkEvents = {
    [K in keyof AffiliateLinkEventsConstructor]: AffiliateLinkEventsConstructor[K];
};