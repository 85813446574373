import {ExperienceCalendarDay} from "../Models/ExperienceCalendarDay";
import {WidgetCalendarDay} from "@bookingkit-private/api-v4/models/widget-calendar-day";
import InvalidApiV4ResponseError from "../Errors/InvalidApiV4ResponseError";

export const fromApiToExperienceCalendarDay = (apiCalendarDay: WidgetCalendarDay): ExperienceCalendarDay => {
    if(!apiCalendarDay.date ) {
        throw InvalidApiV4ResponseError.fromMissingProperty('date', 'string', apiCalendarDay?.date);
    }
    if(!apiCalendarDay.options) {
        throw InvalidApiV4ResponseError.fromMissingProperty('options', 'array', apiCalendarDay?.options);
    }
    if(typeof apiCalendarDay.date !== 'string') {
        throw InvalidApiV4ResponseError.fromInvalidProperty('date', typeof apiCalendarDay?.date);
    }
    if(!Array.isArray(apiCalendarDay.options)) {
        throw InvalidApiV4ResponseError.fromInvalidProperty('options', typeof apiCalendarDay?.options);
    }
    const day = new Date(apiCalendarDay.date);
    return new ExperienceCalendarDay(
        day,
        apiCalendarDay.options!.map(option => {
                if (!option.option_id) {
                    throw InvalidApiV4ResponseError.fromMissingProperty('option_id', 'string', option?.option_id);
                }
                if (typeof option.minimum_available !== 'number') {
                    throw InvalidApiV4ResponseError.fromMissingProperty('option_id', 'string', option?.option_id);
                }
                return {
                    optionId: option.option_id!,
                    availableSlots: option.minimum_available!
                }
            }
        )
    );
}