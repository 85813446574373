import {Locale} from "../../Constants/locales";
import {ColourScheme, DarkColourScheme} from "./Common/ColourScheme";

export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';
export const THEME_AUTO = 'auto';
export const THEMES = [THEME_LIGHT, THEME_DARK] as const;
export const INPUT_THEMES = [THEME_LIGHT, THEME_DARK, THEME_AUTO] as const;
export type WidgetStyleTheme = typeof THEMES[number];
export type InputWidgetStyleTheme = typeof INPUT_THEMES[number];

// #region ConfigurationTypes
export interface BaseWidgetConfiguration {
    id: string,
    locale: Locale,
    instanceId: string,
    options: {
        previewMode: boolean,
        tracking: boolean,
        crashReport: boolean,
        collectTraces: boolean,
        loggingLevel: 'debug' | 'warn' | 'error',
    },
    checkoutTheme: string,
    localisation: Partial<Record<Locale, Record<string, string>>>
    themeConfiguration: {
        mode: WidgetStyleTheme,
        light: {
            colours: Partial<ColourScheme>
        },
        dark: {
            colours: Partial<ColourScheme>
        }
    }
};
// #endregion ConfigurationTypes

export type BaseWidgetConfigurationInput = {
    id: string,
    /** @default false */
    locale?: Locale | false,
    options?: Partial<BaseWidgetConfiguration['options']>,
    checkoutTheme?: string,
    localisation?: Partial<Record<Locale, Record<string, string>>>,
    themeConfiguration?: {
        /** @default auto */
        mode?: InputWidgetStyleTheme,
        light?: {
            colours?: Partial<ColourScheme>
        },
        dark?: {
            colours?: Partial<DarkColourScheme>
        }
    }
};
