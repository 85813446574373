import {applyDefaultOptions} from "../DefaultOptionsMapper";
import {Locale,} from "../../../../Constants/locales";
import {stringToLocale} from "../LocaleMapper";
import {BookNowWidgetConfigurationInput, BookNowWidgetType} from "../../../../Contracts/WidgetTypes/BookNowType";
import {bookNowTriggerStyleMapper} from "./bookNowTriggerStyleMapper";
import {fromInputWidgetThemeToWidgetTheme} from "../BaseWidgetConfigurationMapper";

export default (config: BookNowWidgetConfigurationInput, instanceId: string):BookNowWidgetType => {
    return {
        ...config,
        instanceId,
        options: applyDefaultOptions(config.options ?? {}),
        trigger: !(config.trigger instanceof HTMLElement || Array.isArray(config.trigger)) ? bookNowTriggerStyleMapper(config.trigger ?? {}) : config.trigger as HTMLElement[],
        experienceId: config.experienceId,
        localisation: config?.localisation ?? {} as Record<Locale, Record<string, string>>,
        locale: config.locale ? config.locale : stringToLocale(window.navigator.language),
        checkoutTheme: config.checkoutTheme ?? 'light',
        themeConfiguration: fromInputWidgetThemeToWidgetTheme(config.themeConfiguration),
        openOnLoad: config.openOnLoad ?? false,
    }
}
