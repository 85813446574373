import {GlobalEventBus} from "../GlobalEventBus";
import {AFFILIATE_LINK_EVENTS} from "../../Events/AffiliateLink/Events";
import {IAffiliateLinkService} from "../../Contracts/GloablServices/IAffiliateLinkService";
import {IClientStorageService} from "../../Contracts/GloablServices/IClientStorageService";

const AFFILIATE_LINK_URL_PARAM = 'bklink';
const AFFILIATE_LINK_STORAGE_KEY = 'bklink';
export class AffiliateLinkService implements IAffiliateLinkService {

    private affiliateLink: string | null = null;
    constructor(
        private readonly eventBus: typeof GlobalEventBus,
        private readonly storage: IClientStorageService
    ) {
        let affiliateLink = this.getAffiliateLinkFromUrl();
        if(affiliateLink) {
            this.persistAffiliateLink(affiliateLink);
        } else {
            affiliateLink = this.getAffiliateLinkFromStorage();
        }
        this.setAffiliateLink(affiliateLink);
    }

    private getAffiliateLinkFromUrl(): string | null {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(AFFILIATE_LINK_URL_PARAM) ?? null;
    }

    private getAffiliateLinkFromStorage(): string | null {
        return this.storage.get(AFFILIATE_LINK_STORAGE_KEY) ?? null;
    }

    private setAffiliateLink(affiliateLink: string | null) {
        this.affiliateLink = affiliateLink;
        if (!affiliateLink) {
            return;
        }
        this.eventBus.emit(AFFILIATE_LINK_EVENTS.AFFILIATE_LINK_DETECTED, {affiliateLink});
    }

    private persistAffiliateLink(affiliateLink: string | null) {
        this.storage.set(AFFILIATE_LINK_STORAGE_KEY, affiliateLink);
    }

    getAffiliateLink(): string | null {
        return this.affiliateLink;
    }
}
