export const POSITION_TOP_LEFT = 'top-left';
export const POSITION_TOP_RIGHT = 'top-right';
export const POSITION_BOTTOM_LEFT = 'bottom-left';
export const POSITION_BOTTOM_RIGHT = 'bottom-right';
export const POSITION_CENTER_LEFT = 'center-left';
export const POSITION_CENTER_RIGHT = 'center-right';

export const BUTTON_SIZE_SMALL = 'small';
export const BUTTON_SIZE_MEDIUM = 'medium';

export const BUTTON_ANIMATION_NONE = 'none';
export const BUTTON_ANIMATION_RIPPLE = 'ripple';
export const BUTTON_ANIMATION_SHINE = 'shine';
export const BUTTON_ANIMATION_SHAKE = 'shake';

export const BUTTON_TYPE_SHADOW = 'shadow';
export const BUTTON_TYPE_FLAT = 'flat';
export const BUTTON_TYPE_OUTLINED = 'outlined';

export const BUTTON_COLOR_NEUTRAL = 'neutral';
export const BUTTON_COLOR_PRIMARY = 'primary';

export const BUTTON_DISTANCE_STICKY = 'sticky';
export const BUTTON_DISTANCE_FLOATING = 'floating';

export const TICKET_ICON = 'ticket';
export const SHOPPING_BAG_ICON = 'shopping-bag';



export type WidgetStylePosition = typeof POSITION_TOP_LEFT | typeof POSITION_TOP_RIGHT | typeof POSITION_BOTTOM_LEFT | typeof POSITION_BOTTOM_RIGHT | typeof POSITION_CENTER_LEFT | typeof POSITION_CENTER_RIGHT;
export type WidgetStyleButtonSize = typeof BUTTON_SIZE_SMALL | typeof BUTTON_SIZE_MEDIUM;
export type WidgetStyleButtonAnimation = typeof BUTTON_ANIMATION_NONE | typeof BUTTON_ANIMATION_RIPPLE | typeof BUTTON_ANIMATION_SHINE | typeof BUTTON_ANIMATION_SHAKE;
export type WidgetStyleButtonType = typeof BUTTON_TYPE_SHADOW | typeof BUTTON_TYPE_FLAT | typeof BUTTON_TYPE_OUTLINED;
export type WidgetStyleButtonColor = typeof BUTTON_COLOR_NEUTRAL | typeof BUTTON_COLOR_PRIMARY;
export type WidgetStyleButtonDistance = typeof BUTTON_DISTANCE_STICKY | typeof BUTTON_DISTANCE_FLOATING;

export type TriggerStyle =   {
    /** @default "ticket" */
    icon: typeof TICKET_ICON | typeof SHOPPING_BAG_ICON | false,
    /** @default "bottom-right" */
    position: WidgetStylePosition,
    /** @default "true" */
    label: boolean,
    /** @default "medium" */
    buttonSize: WidgetStyleButtonSize,
    /** @default "none" */
    buttonAnimation: WidgetStyleButtonAnimation,
    /** @default "shadow" */
    buttonType: WidgetStyleButtonType,
    /** @default "neutral" */
    buttonColor: WidgetStyleButtonColor
    /** @default "floating" */
    buttonDistance: WidgetStyleButtonDistance
};

export type TriggerStyleInput = Partial<TriggerStyle>;

